import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import r2wc from "react-to-webcomponent";

const AppWC = r2wc(App, React, ReactDOM, {
  props: {
    projectKey: "string",
  },
});

customElements.get("open-replay") ||
  customElements.define("open-replay", AppWC);

/*
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
*/
