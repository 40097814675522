import OpenReplay from "@openreplay/tracker";
import React from "react";

export default function App({ projectKey }) {
  console.log({ projectKey });
  React.useEffect(() => {
    if (projectKey) {
      new OpenReplay({ projectKey }).start();
      console.log("Openreplay tracker started!");
    }
  }, [projectKey]);

  return (
    <div style={{ border: "1px solid #ccc", padding: "24px", margin: "24px" }}>
      Openreplay plugin!
    </div>
  );
}
